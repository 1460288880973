export const tableList = [
  {
    title: "企业名称",
    key: "EnterpriseFullName",
    width: 200,
    maxLength: 50,
  },
  {
    title: "统一社会信用代码",
    key: "EnterpriseCreditCode",
    width: 200,
    maxLength: 18,
  },
  {
    title: "法定代表人",
    key: "LegalPersonIName",
    width: 150,
    maxLength: 20,
  },
  {
    title: "注册国家",
    key: "RegisterNation",
    width: 150,
    maxLength: 20,
  },
  {
    title: "境内/境外",
    key: "DomesticOverseas",
    width: 150,
    maxLength: 2,
  },
  {
    title: "注册资本",
    key: "RegisteredCapital",
    width: 150,
    maxLength: 10,
  },
  {
    title: "营业执照注册日期",
    key: "EnterpriseRegisterDate",
    width: 200,
    maxLength: 20,
  },
  {
    title: "营业执照有效日期",
    key: "EnterpriseEffectiveDate",
    width: 200,
    maxLength: 20,
  },
  {
    title: "营业执照住所",
    key: "InvoiceTitleAddress",
    width: 200,
    maxLength: 200,
  },
  {
    title: "税务登记号",
    key: "TAXID",
    width: 150,
    maxLength: 18,
  },
  {
    title: "经营范围",
    key: "BusinessScope",
    width: 200,
    maxLength: 500,
  },
  {
    title: "企业所在省",
    key: "Province",
    width: 200,
    maxLength: 10,
  },
  {
    title: "市",
    key: "City",
    width: 150,
    maxLength: 10,
  },
  {
    title: "区",
    key: "County",
    width: 150,
    maxLength: 10,
  },
  {
    title: "企业所在道路门牌",
    key: "Road",
    width: 200,
    maxLength: 200,
  },
  {
    title: "企业行业分类一级",
    key: "TCKind",
    width: 200,
    maxLength: 200,
  },
  {
    title: "企业行业分类二级",
    key: "TCBig",
    width: 200,
    maxLength: 200,
  },
  {
    title: "企业行业分类三级",
    key: "TCMid",
    width: 200,
    maxLength: 200,
  },
  {
    title: "企业行业分类四级",
    key: "TCSmall",
    width: 200,
    maxLength: 200,
  },
  {
    title: "发票抬头企业全名",
    key: "InvoiceTitleName",
    width: 200,
    maxLength: 50,
  },
  {
    title: "企业联系地址",
    key: "InvoiceTitleAddress",
    width: 200,
    maxLength: 200,
  },
  {
    title: "纳税人识别号",
    key: "InvoiceTitleCreditCode",
    width: 150,
    maxLength: 18,
  },
  {
    title: "银行账户",
    key: "InvoiceTitleBankerNumber",
    width: 200,
    maxLength: 20,
  },
  {
    title: "企业联系电话",
    key: "InvoiceTitlePhone",
    width: 150,
    maxLength: 11,
  },
  {
    title: "银行账户开户行",
    key: "InvoiceTitleBankerName",
    width: 200,
    maxLength: 50,
  },
  {
    title: "开户行所在地省",
    key: "BankProvince",
    width: 150,
    maxLength: 200,
  },
  {
    title: "开户行所在地市",
    key: "BankCity",
    width: 150,
    maxLength: 10,
  },
  {
    title: "开户行所在地区",
    key: "BankArea",
    width: 150,
    maxLength: 10,
  },
  {
    title: "法人身份证号",
    key: "LegalPersonIIDCard",
    width: 150,
    maxLength: 18,
  },
  {
    title: "法人手机号码",
    key: "LegalPersonlPhone",
    width: 200,
    maxLength: 11,
  },
  {
    title: "法人身份证起始日期",
    key: "LegalPersonalIDCardStartDate",
    width: 150,
    maxLength: 20,
  },
  {
    title: "法人身份证有效期",
    key: "LegalPersonlIDCardDate",
    width: 150,
    maxLength: 20,
  },
  {
    title: "财务经办人姓名",
    key: "CFOName",
    width: 150,
    maxLength: 20,
  },
  {
    title: "财务经办人身份证号",
    key: "CFOIDCardNo",
    width: 200,
    maxLength: 18,
  },
  {
    title: "财务经办人手机号码",
    key: "CFOPhone",
    width: 150,
    maxLength: 11,
  },
  {
    title: "财务经办人身份证起始日期",
    key: "CFOIDCardStartDate",
    width: 200,
    maxLength: 20,
  },
  {
    title: "财务经办人身份证有效期",
    key: "CFOIDCardValidDate",
    width: 200,
    maxLength: 20,
  },
  {
    title: "企业性质",
    key: "BenefitWayName",
    width: 150,
    maxLength: 20,
  },
];

export const detailImgList = [
  {
    title: "法人身份证(正面)",
    key: "LegalPersonIDCardOnURL",
  },
  {
    title: "法人身份证(反面)",
    key: "LegalPersonIDCardBackURL",
  },
  {
    title: "财务经办人身份证(正面)",
    key: "CFOIDCardUrl",
  },
  {
    title: "财务经办人身份证(反面) ",
    key: "CFOIDCardBackUrl",
  },
  {
    title: "营业执照(原件三合一正本)",
    key: "BusinessLicenseURL",
  },
  {
    title: "开户证明",
    key: "AccountLicenceUrl",
  },
];
