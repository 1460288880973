<template>
  <div class="enterpriseDetaile">
    <el-dialog
      width="1600px"
      title="企业详情"
      center
      :visible.sync="detailDialog"
      append-to-body
      v-if="detailDialog"
      :close-on-click-modal="false"
      class="detail-dialog"
    >
      <div
        class="content"
        v-loading="loading"
      >
        <template v-for="(it,idx) in detailImgList">
          <div
            class="upBox"
            v-if="detailInfo[it.key]"
            :key="idx"
          >
            <el-image
              class="img"
              :src="detailInfo[it.key]"
              :preview-src-list="srcList"
            ></el-image>
            <div class="upText">{{ it.title }}</div>
          </div>
        </template>
      </div>

      <el-form
        :model="detailInfo"
        label-width="185px"
        disabled
      >
        <div
          class="title"
          style="font-weight: bold;font-size: 16px;margin: 10px;"
        >基本资料</div>
        <div
          class="content"
          style="justify-content: left"
        >
          <el-form-item
            :label="it.title"
            v-for="(it,idx) in tableList"
            :key="idx"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailInfo[it.key]"
              placement="top-start"
              v-if="detailInfo[it.key].length > 20"
            >
              <el-input v-model="detailInfo[it.key]"></el-input>
            </el-tooltip>

            <el-input
              v-else
              v-model="detailInfo[it.key]"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { tableList, detailImgList } from './data.js'
import { EnterpriseDetail } from '@/api/bigCustomerManage/index.js'

export default {
  data() {
    return {
      loading: false,
      detailDialog: false,
      detailInfo: {},
      tableList,
      detailImgList,
      srcList: [],

    }
  },
  methods: {
    watchDetail(item) {
      this.loading = true;

      let params = {
        userID: item.UserID,
      }
      item.EnterpriseCreditCode ? (params.enterpriseCreditCode = item.EnterpriseCreditCode) : (params.enterpriseFullName = item.GoodsOwnerNameChild)

      EnterpriseDetail(params)
        .then((res) => {
          this.detailInfo = res.data || {}
          let { LegalPersonIDCardOnURL = '', LegalPersonIDCardBackURL = '', CFOIDCardUrl = '', CFOIDCardBackUrl = '', BusinessLicenseURL = '', AccountLicenceUrl = '' } = this.detailInfo

          if (LegalPersonIDCardOnURL) this.srcList.push(LegalPersonIDCardOnURL)
          if (LegalPersonIDCardBackURL) this.srcList.push(LegalPersonIDCardBackURL)
          if (CFOIDCardUrl) this.srcList.push(CFOIDCardUrl)
          if (CFOIDCardBackUrl) this.srcList.push(CFOIDCardBackUrl)
          if (BusinessLicenseURL) this.srcList.push(BusinessLicenseURL)
          if (AccountLicenceUrl) this.srcList.push(AccountLicenceUrl)
          this.detailDialog = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>

<style lang="scss">
.detail-dialog {
  .el-dialog {
    margin-top: 8vh !important;

    .el-dialog__body {
      padding: 10px !important;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .img {
      width: 200px;
      height: 200px;
    }

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }
  }
}
</style>